import React from 'react'
import ContactIntro from './components/ContactIntro'

const ContactUs = () => {
  return (
    <>
        <ContactIntro />
    </>
  )
}

export default ContactUs