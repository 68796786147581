import React from 'react'
import GraphIntro from './components/GraphIntro'
import './graphics.css'

const Graphics = () => {
  return (
    <>
        <GraphIntro />
    </>
  )
}

export default Graphics